textarea {
    &[cols] {
        height: auto;
    }
}

.form-group {
    display: block;
}

.form-label {
    display: block;
    margin-bottom: 0.375rem;
    font-weight: 600;
    font-size: px2rem(14px);
}

.range-label {
    display: block;
    margin-bottom: -10px;
}

.custom-range {
    height: 38px !important;
}

.form-label-small {
    float: right;
    font-weight: 400;
    font-size: $small-font-size;
}

.form-footer {
    margin-top: 1rem;
}

.custom-select,
.custom-file {
    //display: block;
    //width: 100%;
    line-height: 1.2;
    font-size: 0.95rem;
}

.custom-control {
    user-select: none;
}

.custom-controls-stacked {
    .custom-control {
        margin-bottom: 0.25rem;
    }
}

.custom-control-label {
    vertical-align: middle;

    &:before {
        border: 1px solid $input-border-color;
        background-color: #fff;
        background-size: 0.5rem;
    }
}

.custom-control-description {
    line-height: 1.5rem;
}

.input-group-prepend,
.input-group-append,
.input-group-btn {
    font-size: px2rem(15px);

    > .btn {
        height: 100%;
        border-color: $border-color;
    }
}

.input-group-prepend > .input-group-text {
    border-right: 0;
}

.input-group-append > .input-group-text {
    border-left: 0;
}

/**
Icon input
 */
.input-icon {
    position: relative;

    .form-control:not(:last-child) {
        padding-right: 2.5rem;
    }

    .form-control:not(:first-child) {
        padding-left: 2.5rem;
    }
}

.input-icon-addon {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    color: $text-muted-dark;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 2.5rem;
    pointer-events: none;

    &:last-child {
        left: auto;
        right: 0;
    }
    i:focus {
        outline: 0;
    }
}

.form-fieldset {
    background: $gray-100;
    border: 1px solid $gray-200;
    padding: 1rem;
    border-radius: 3px;
    margin-bottom: 1rem;
}

.form-required {
    color: $red;

    &:before {
        content: " ";
    }
}

.state-valid {
    padding-right: 2rem;
    background: str-replace(
            url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='#{$green}' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-check'><polyline points='20 6 9 17 4 12'></polyline></svg>"),
            "#",
            "%23"
        )
        no-repeat center right 0.5rem/1rem;
}

.state-invalid {
    padding-right: 2rem;
    background: str-replace(
            url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='#{$red}' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-x'><line x1='18' y1='6' x2='6' y2='18'></line><line x1='6' y1='6' x2='18' y2='18'></line></svg>"),
            "#",
            "%23"
        )
        no-repeat center right 0.5rem/1rem;
}
.curser-pointer {
    cursor: pointer
}
.form-help {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    text-align: center;
    line-height: 1rem;
    color: $text-muted;
    background: $gray-100;
    border-radius: 50%;
    font-size: px2rem(12px);
    transition: 0.3s background-color, 0.3s color;
    text-decoration: none;
    cursor: pointer;
    user-select: none;

    &:hover,
    &[aria-describedby] {
        background: $primary;
        color: #fff;
    }
}


// Custom Checkbox Style

@supports (-webkit-appearance: none) or (-moz-appearance: none) {
  .custom-checkbox {
    width: 18px;
    height: 18px;
    appearance: none;
    margin: 0;
    padding: 0;
    border: 1px solid $gray-400;
    background: $white;
    border-radius: 3px;
    position: relative;
    cursor: pointer;
    transition: background 0.3s ease, border-color 0.3s ease;

    &:checked {
      background: $primary;
      border-color: $primary;
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.9;
      background: $light;
    }

    &:disabled:checked{
      background: $primary;
      opacity: 0.7;
    }

    &:after {
      content: '';
      position: absolute;
      left: 5px;
      top: 2px;
      width: 5px;
      height: 9px;
      border: 2px solid $white;
      border-top: 0;
      border-left: 0;
      transform: rotate(43deg);
      opacity: 0;
      transition: opacity 0.3s ease, transform 0.3s ease;
    }

    &:checked:after {
      opacity: 1;
    }
  }
}