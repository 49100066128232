.table {
    table-layout:fixed;
    thead {
        //background: $table-accent-bg;

        th {
            border-top: 0;
            border-bottom-width: 1px;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
        }
    }

    th {
        color: $text-muted;
        text-transform: uppercase;
        font-size: $h5-font-size;
        font-weight: 400;
    }

    .cell-mx-width {
        max-width: 90%;
        -ms-text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        position: relative;
        display: inline-block;
    }
}


.table-head th {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}


.table-md {
    th,
    td {
        padding: 0.5rem;
    }
}

.table-vcenter {
    td {
        vertical-align: middle;
        height: 55px;
        padding: 0.55rem;
    }
    th {
        vertical-align: middle;
    }
}

.table-center {
    td,
    th {
        text-align: center;
    }
}

.table-striped {
    tbody tr:nth-of-type(odd) {
        background: transparent;
    }

    tbody tr:nth-of-type(even) {
        background-color: $table-accent-bg;
    }
}

.table-calendar {
    margin: 0 0 0.75rem;

    td,
    th {
        border: 0;
        text-align: center;
        padding: 0 !important;
        width: (100%/7);
        line-height: 2.5rem;
    }

    td {
        border-top: 0;
    }
}

.table-calendar-link {
    line-height: 2rem;
    min-width: calc(2rem + 2px);
    display: inline-block;
    border-radius: 3px;
    background: $gray-100;
    color: $body-color;
    font-weight: 600;
    transition: 0.3s background, 0.3s color;
    position: relative;

    &:before {
        content: "";
        width: 4px;
        height: 4px;
        position: absolute;
        left: 0.25rem;
        top: 0.25rem;
        border-radius: 50px;
        background: $primary;
    }

    &:hover {
        color: #fff;
        text-decoration: none;
        background: $primary;
        transition: 0.3s background;

        &:before {
            background: #fff;
        }
    }
}

.table-header {
    cursor: pointer;
    transition: 0.3s color;

    &:hover {
        color: $body-color !important;
    }

    &:after {
        content: "\f0dc";
        font-family: FontAwesome;
        display: inline-block;
        margin-left: 0.5rem;
        font-size: 0.75rem;
    }
}

.table-header-asc {
    color: $body-color !important;

    &:after {
        content: "\f0de";
    }
}

.table-header-desc {
    color: $body-color !important;

    &:after {
        content: "\f0dd";
    }
}

// custom Datatable Styles

.customTable {
    .kwRiGp,
    .card-title-custom,
    .jqBTNs {
        font-family: "Ubuntu", sans-serif;
        font-size: 1.125rem;
        line-height: 1.2;
        font-weight: 500 !important;
        color: rgba(0, 0, 0, 0.87);
    }
    header {
        padding: 1rem 1.5rem;
        display: flex;
        flex-grow: 1;
    }
    .jEDPQU {
        display: grid;
        // height: auto;
    }
    .rdt_TableHeader {
        border-bottom: solid 1px #ddd;
    }
    .rdt_Table {
        font-size: $font-size-base;
        .rdt_TableHeadRow {
            min-height: 30px;
            border-top: solid 1px #ddd;
            .rdt_TableCol {
                font-size: 0.875rem;
                text-transform: uppercase;
                color: $text-muted;
                padding: 12px 12px;
            }
            .rdt_TableCol_Sortable {
                position: relative;
                span {
                    position: absolute;
                    left: 100%;
                }
            }
        }
        .custom-control {
            min-height: 38px;
        }
        .rdt_TableBody {
            .rdt_TableCell {
                font-size: $font-size-base;
                color: $gray-700;
                padding: 12px 12px;
            }
        }
    }
}

.__react_component_tooltip {
    max-width: 300px !important;
    white-space: normal !important;
}
.__react_component_tooltip.show {
    opacity: 1 !important;
}

// Responsive Table width classes
.w-20-res {
    width: 20%;
    @include media(md) {
        width: 150px;
    }
}
.w-15-res {
    width: 15%;
    @include media(md) {
        width: 120px;
    }
}
.w-12-res {
    width: 12%;
    @include media(md) {
        width: 100px;
    }
}
.w-10-res {
    width: 10%;
    @include media(md) {
        width: 90px;
    }
}
.w-8-res {
    width: 8%;
    @include media(md) {
        width: 80px;
    }
}
.col-sm-250 {
    @include media(lg) {
        width: calc(100% - 250px);
    }
    @include media(lg) {
       .table {
            min-width: 700px;
       }
    }
  }

.max-width-100 {
    max-width: 100%;
  }