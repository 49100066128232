/* General helper styles
--------------------------------------*/
.w-7 {
  width: 2rem !important;
  margin-right: 10px;
}
.custom-i-addon {
  pointer-events: auto !important;
}

.curser-pointer {
  cursor: pointer
}

.xx-large-icon{
  font-size: xx-large;
}
.large-icon{
  font-size: large;
}
.toggle-pwd {
  border: none;
  background: inherit;
}

.pos-relative {
  position: relative;
}
.txt-trans {
  text-transform: uppercase;
  letter-spacing: 0.75px;
  color: #FFFFFF;
  text-transform: uppercase;
  opacity: 1;
  font-size: 15px;
}

.sort-direction {
  display: inline-block;
  height: 0;
  width: 0;
  margin: 2px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;

  &.asc {
    border-bottom: 5px solid white;
  }

  &.desc {
    border-top: 5px solid white;
  }

  &.default {
    border-top: 5px solid #9aa0ac;
  }
}
.page-title {
  margin: 0;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 2.5rem;
  font-family: $font-family-secondary;
  color: $gray-800;
  margin: 0;
}

.card-title-custom {
  font-family: $font-family-secondary;
  font-size: 1.16rem;
  line-height: 2rem;
  font-weight: 400;
  margin: 0px;
}

#root {
  display: flex;
  width: 100%;
  flex-direction: column;
  height: 100vh;
}

// Login page
.login::after {
  background-attachment: fixed;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  content: "";
  position: fixed;
  opacity: 0.2;
  z-index: -1;
}

.login .power-logo {
  width: 150px;
  cursor: pointer;
}

.login::before {
  position: absolute;
  width: 100%;
  height: 100%;
  content: "";
  background: url("../../img/bg.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.desc{
  font-family: $font-family-base;
  font-size: 16px;
  color: white;
  text-align: left;
}
.btn-red{
  background: #E72729;
}
.btn-black{
    background: #000000;
}
.modal-dialog {
  margin: auto;
}
.mg-left {
  margin-left: 15px;
}
.mg-bt-40 {
  margin-bottom: 40px;
}
.authpage::after {
  content: "";
}

@keyframes gradient {
  50% {
    background-position: 100% 0%;
  }
}

// Custom Link Button

.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline;
  outline: none;
  margin: 0;
  padding: 0;
}

.link-button:hover,
.link-button:focus {
  text-decoration: none;
}
.login-btn-in {
  width: 190px;
  height: 60px;
  box-shadow: 0px 0px 10px #00000033;
  border-radius: 10px;
  background: #e72729 !important;
  border:  #e72729 !important;
}

.login-btn {
  display: flex;
  justify-content: center;
}
.login-btn-in:hover {
	color:white;
}
.cursor-auto {
  cursor: auto !important;
}

.table-min-height {
  min-height: 200px;
  max-height: calc(100vh - 373px);
  overflow: auto;
  white-space: nowrap;
}

.btn-link-dropdown {
  color: $red;
  text-decoration: none
}

.capitilize-column {
  text-transform: capitalize;
}

.user-input {
  background: white !important;
  color: black !important;
  border: none;
  border-radius: 5px;
  height: 31px;
}
.mg-rt-3 {
  margin-right: 20px;
}

.mg-rt-80 {
  margin-right: 80px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
 border: 1px solid rgba(0, 40, 100, 0.12);
 -webkit-text-fill-color: black;
 -webkit-box-shadow: 0 0 0px 1000px white inset;
 transition: background-color 5000s ease-in-out 0s;
 caret-color: black;
}

.width-10 {
  width: 10%;
}

.width-15 {
  width: 15%!important;
}

.width-25 {
  width: 25%!important;
}

.width-20 {
  width: 20%;
}

.width-30 {
  width: 30%;
}

.width-28 {
  width: 28%;
}

.width-12 {
  width: 12%;
}

.width-8 {
  width: 8%;
}

.w-320 {
  width: 320px;
}

.w-250 {
  width: 250px;
}

.w-70 {
  width: 70px;
}

.w-260 {
  width: 260px;
}

.muted{
  background-color: dimgrey !important;
  color: hsl(0, 0%, 60%) !important;
}
.curser-na{
  cursor: not-allowed;
}
.toaster {
  font-family: $font-family-secondary !important;
  font-size: 16px;
}
.user-fg{
  margin-bottom: 0 !important;
  height: 85px;
}
.user-fgr{
  margin-bottom: 0 !important;
  height: 95px;
}
.form-control:focus{
  border-color: unset !important;
  box-shadow: unset !important;
}
.form-control.is-invalid {
  border: none !important;
  background-image: none !important;
  border-color: none !important;
}
.form-control.is-invalid:focus{
  border: none !important;
  background-image: none !important;
}
.login-card {
  height: 760px;
  width: 652px;
}
.login-pd {
  padding-right: 113px;
  padding-left: 113px;
}
.logo-size {
  width: 66.42px;
  height: 50px
}

.mb-25 {
  margin-bottom: 25px;
}

.btn-red {
  background: #E72729;
}
.btn-black {
    background: #000000;
}

.txt-uppercase {
  text-transform: uppercase;
}
.display-none {
  display: none;
}
.set-password {
  position: absolute;
  width: 100%;
  height: 100%;
  content: "";
  background: url("../../img/bg.png");
  background-size: cover;
  background-repeat: no-repeat;  .modal-dialog {
    max-width: unset;
    margin: 0 auto;
    width: 615px;
    .modal-content{
      background: transparent;
    }
  }
}
.notFound{
  left: 0;
  line-height: 200px;
  margin-top: -100px;
  position: absolute;
  text-align: center;
  top: 50%;
  width: 100%;
  font-size: 100px;
  color: grey;
}

.fullloader {
  display: contents;
  position: unset;
  height: 5.5rem;
  width: 5.5rem;
  color: $blue;

  &:before,
  &:after {
    width: 5.5rem;
    height: 5.5rem;
    margin: (-2.5rem / 2) 0 0 (-2.5rem / 2);
    position: absolute;
    content: "";
    top: 50%;
    left: 50%;
  }

  &:before {
    border-radius: 50%;
    border: 5px solid currentColor;
    opacity: 0.15;
  }

  &:after {
    animation: loader 0.6s linear;
    animation-iteration-count: infinite;

    border-radius: 50%;
    border: 5px solid;
    border-color: transparent;
    border-top-color: #d63939;
    box-shadow: 0 0 0 1px transparent;
  }
}

.loadingOpacity {
  opacity: 0.2;
}

.close{
  padding: 0;
  background-color: transparent;
  border: 0;
  opacity: 1;
  color: #E72729;;
  text-shadow: none;
  &:focus{
      outline: unset;
      opacity: 1;
  }
}

.select-sdd {
  position: relative;
  z-index: 9999;
  font-size: 15px;
  color: #1d1d1d;
  letter-spacing: 0.38px;
}

a:focus-visible {
  outline: none !important;
}

.login-footer{
	position: relative;
	background: unset;
	padding: 0px 0px 0px 40px;
}

.login-footer a:not(.btn) {
	color: #6e7687;
}

.header-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: #1d1d1d !important;
  border: unset;
  z-index: 100;
}

.page-scroll{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: calc(100% - 260px);
}
.fixed-header{
  position: relative;
  z-index : 9;
}

.search-input {
    border-radius: 3px 0 0 3px;
    min-height: 36px;
}
.select-sdd {
  .react-select__menu-list {
    max-height: 325px;
  }
  .react-select__option {
    display: flex;
    align-items: center;
  }
  .react-select__control {
      border-color: #999!important;
      box-shadow:none!important;
      min-height: 36px;
      &:hover, &.react-select__control--menu-is-open {
        border-color: #999!important;
      }
  }
  .react-select__value-container  {
    flex-wrap: nowrap;
  }
  .react-select__indicator {
      padding: 7px;
  }
  .react-select__indicator-separator {
      margin-top: 7px;
      margin-bottom: 7px;
  }
}

.input-group-append, .input-group-prepend {
  height: 100%;
}

.card-table tbody tr td:not(:first-child) {
  padding-left: 13px;
}

.h-150{ 
  height: 150px;
}
.h-300{ 
  height: 300px;
}
.h-260{ 
  height: 260px;
}


// Not found Page


.not-found-page {
  min-height: 100vh;
  padding: 60px 0 98px;
  position: relative;
  display: flex;
  justify-content: center;
  color: #fff;
  .logo {
    width: 180px;
  }
  .not-found-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center
  }
  .not-found-wrapper {
    align-items: center
  }
  .not-found-title {
    font-size: 5rem;
    font-weight: 100;
    margin-bottom: .75rem;
    padding-top: .75rem
  }
  
  .not-found-message {
    font-size: 21px;
    font-weight: 300;
    margin-bottom: 4rem;
    padding: 0 3rem;
    text-align: center;
  }
}


.back-button {
  align-items: center;
  border: 2px solid var(--color-4);
  border-radius: 2rem;
  display: flex;
  font-size: 12px!important;
  font-weight: 750!important;
  height: 38px;
  letter-spacing: .5px;
  padding: .5rem 1.5rem;
  text-decoration: none
}

.left-arrow-icon {
  fill: #fff;
  display: flex;
  font-size: 1rem;
  margin-right: .75rem
}

.not-found-footer {
  align-items: center;
  bottom: 0;
  left: 0;
  position: fixed;
  width: 100%
}
