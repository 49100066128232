.dropdown {
	display: inline-block;
}

.dropdown-menu {
	box-shadow: $dropdown-box-shadow;
	min-width: 12rem;
}

.dropdown-item {
	color: $text-muted-dark;
}

.dropdown-menu-arrow {
	&:before {
		position: absolute;
		top: -6px;
		left: 12px;
		display: inline-block;
		border-right: 5px solid transparent;
		border-bottom: 5px solid $border-color;
		border-left: 5px solid transparent;
		border-bottom-color: rgba(0, 0, 0, 0.2);
		content: '';
	}

	&:after {
		position: absolute;
		top: -5px;
		left: 12px;
		display: inline-block;
		border-right: 5px solid transparent;
		border-bottom: 5px solid #fff;
		border-left: 5px solid transparent;
		content: '';
	}

	&.dropdown-menu-right {
		&:before,
		&:after {
			left: auto;
			right: 12px;
		}
	}
}

.dropdown-toggle {
	user-select: none;
	cursor: pointer;

	&:after {
		vertical-align: 0.155em;
	}

	&:empty:after {
		margin-left: 0;
	}
}

.dropdown-icon {
	color: $text-muted;
	margin-right: .5rem;
	margin-left: -.5rem;
	width: 1em;
	display: inline-block;
	text-align: center;
	vertical-align: -1px;
}

.custom-sel .react-select__option {
	border-bottom: solid 1px #fafafa;
}

.custom-sel .react-select__option--is-selected, .custom-sel .react-select__option--is-selected:active {
	background-color: #f2f2f2;
	color: #495057;
  }