// stylelint-disable declaration-no-important

.theme-dark {
    & {
        color: $dark-mode-text;
        background-color: $dark;
    }

    .card,
    .list-group-item,
    .footer:not(.footer-transparent),
    .modal-content,
    .modal-header,
    .flatpickr-calendar {
        // background-color: $dark-mode-light;
        background-color: $dark-mode-darkest;
        color: inherit;
    }

     .dropdown-menu {
        background-color: #2a2e33 !important;
        .dropdown-divider {
            border-color: #40464d;
        }
        &:hover,
        &:focus {
            color: #b1adad;
            background-color: #1f2328;
        }
        &.dropdown-menu-arrow:after {
            border-bottom: 5px solid #2a2e33;
        }
    }
    .card {
        border-color: $dark-mode-lighten;
        .card-header {
            border-bottom: solid 1px $dark-mode-lighten;
        }
    }

    .list-group-item {
        border-color:$dark-mode-lighten;
    }

    .btn-white,
    .form-file-button {
        //@include button-variant($dark-mode-lighten, $border-color, $dark-mode-text, rgba($dark, 0), $border-color, $dark-mode-text);
        background-image: none;
    }

    .form-check-input:not(:checked),
    .form-select,
    .form-file-text,
    .form-control,
    .form-selectgroup-label,
    .flatpickr-input.flatpickr-input,
    .form-selectgroup-check,
    .form-imagecheck-figure:before {
        background-color: $dark-mode-dark;
        color: $dark-mode-text;
    }

    .page-title,
    .table td,
    .text-wrap table td{
        color: $dark-mode-text;
    }

    .table th,
    .text-wrap table th,
    .table td,
    .text-wrap table td {
        border-color: $dark-mode-lighten;
    }

    .form-control:focus,
    .dataTables_wrapper .dataTables_length select:focus,
    .dataTables_wrapper .dataTables_filter input:focus {
        border-color: $dark-mode-darken;
    }

    .form-control-plaintext {
        color: $dark-mode-text;
    }

    .input-group-flat .input-group-text {
        background-color: $dark-mode-darken;
    }

    .page-link {
        border: 1px solid $dark-mode-light;
        background-color: $dark-mode-darkest;
        color: $dark-mode-text;
    }

    .page-item.disabled .page-link {
        background-color: $dark-mode-dark;
        border-color: $dark-mode-light;
        color: $gray-500;
    }

    .page-item.active .page-link {
        border: 1px solid $dark-mode-dark;
    }

    .highlight {
        background-color: $dark-mode-darken;
    }

    .avatar {
        background-color: $dark-mode-lighten;
    }

    .avatar-list-stacked .avatar {
        box-shadow: 0 0 0 2px $dark;
    }

    .markdown,
    .markdown > *,
    .btn-close {
        color: inherit;
    }

    .apexcharts-text {
        fill: $dark-mode-text;
    }

    .apexcharts-legend-text {
        color: inherit !important;
    }
}
