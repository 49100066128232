.media-icon {
	width: 2rem;
	height: 2rem;
	line-height: 2rem;
	text-align: center;
	border-radius: 100%;
}

.media-list {
	margin: 0;
	padding: 0;
	list-style: none;
}